'use strict';

import {
    LxReactControl
} from "LxComponents"

/**
 * control
 * showGroupDetails     display both the room and category name
 * displayRoomName      display the room name instead of the category name
 * hideActionElements
 * displayControlName   important for controls like the LightControl or the AudioZone. If there is more than one of them
 *                      in a room, it will be necessary to show the name to tell them apart.
 * contentDetail        an object passed on to the control content as detail
 */

class ControlBaseCard extends GUI.TableViewV2.Cells.CardsCell {
    //region Getter
    get didReceiveAllStates() {
        return this._allStatesReceived;
    }

    /**
     * Will always return a promise, either one that pends until the states arrive or one that resolves immediately
     * if the states are already known.
     * @returns {Q.Promise<unknown>|Q.Promise<T>}
     */
    get waitingForStatesPromise() {
        if (this.didReceiveAllStates) {
            return Q.resolve();
        } else {
            this._waitingForStatesDefer = this._waitingForStatesDefer || Q.defer();
            return this._waitingForStatesDefer.promise;
        }
    } //endregion Getter


    //region Setter
    set didReceiveAllStates(hasAllStates) {
        this._allStatesReceived = hasAllStates; // prepare/handle a defer that will be minded when trying to navigtate into the controlContent.

        if (hasAllStates) {
            // if there is a defer, resolve & reset back to null - so the next time a new defer is prepared
            this._waitingForStatesDefer && this._waitingForStatesDefer.resolve();
            this._waitingForStatesDefer = null;
        } else {
            // we are waiting for states, if there is no defer, prepare one
            this._waitingForStatesDefer = this._waitingForStatesDefer || Q.defer();
        }
    } //endregion Setter


    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
        Object.assign(this, StateHandler.Mixin, CellContextMenuHandler.Mixin);
        this._messageCenterButtonAlreadyShown = false;
        this._lockButtonAlreadyShown = false;
        this.didReceiveAllStates = true; // set initially to true, for controls without states
        // initialize with values that cannot are sure to be different from initial values.

        this.__prevTintColor = -1;
        this.__prevTitle2 = -1;
        this.__prevTextColor = -1;
        this.__prevSubtitle = -1;
        this.__prevIconColor = -1;
        this.__prevStateIcon = -1;
        this.__prevSmallIcon = -1;
        this.__prevTintColor = -1; // don't use animationframes until the view is visible (i.e. it's being told to by the viewController)

        this.useAnimationFrames = false;
    }

    setContent(content) {
        this.control = content.control; //delete content.control;

        this._contentDetail = content.contentDetail;
        content.iconSrc = this.control.getIcon();
        content.title = this.getTitle(content) || NBR_SPACE;
        content.title2 = this.control.getName() || NBR_SPACE;
        content.subtitle = this.control.getStateTextShort() || !this.displayAsCell(content) && NBR_SPACE;
        super.setContent(...arguments); // Used for our UI-Test

        if (Debug._CYPRESS_TESTS_ACTIVE) {
            this.element.attr("data-uuid", this.control.uuidAction);
            this.element.attr("data-title", this.control.name);
        }

        this.control.prepareDataForContentScreen();
    }

    viewDidLoad() {
        Debug.BaseCard && console.log(this.viewId, "viewDidLoad");
        var vdlResp = super.viewDidLoad(...arguments);
        this.elements.controlsPlaceholder = $('<div class="content__controls"></div>');
        this.elements.iconPlaceholder = this.element.find(".icon-placeholder__icon");
        this.elements.contentContainer.append(this.elements.controlsPlaceholder);
        this.states = this.control.getStates();
        return Q(vdlResp).then(function () {
            return this._updateControls().then(function () {
                if ((ActiveMSComponent.isExpertModeLightEnabled() || ActiveMSComponent.isExpertModeEnabled()) && !NavigationComp.getCurrentActivityType()) {
                    this._setupListener();
                }

                this._alreadyAddedPreventSortingListeners = false;

                return this.appendReactComp({
                    reactComp: LxReactControl,
                    compProps: {
                        controlUuid: this.control.uuidAction,
                        displayAsCell: this.content.displayAsCell,
                        showGroupDetails: this.content.showGroupDetails,
                        displayRoomName: this.content.displayRoomName,
                        isInAmbientMode: this.content.isInAmbientMode,
                        isShortcut: this.content.isShortcut,
                        fromSearchResults: this.content.fromSearchResults,
                        iconClass: this.content.iconClass,
                        showContextMenu: (ev) => {
                            this._showSortingContextMenu(ev)
                        }
                    }
                });
            }.bind(this));
        }.bind(this));
    }

    viewWillAppear() {
        Debug.BaseCard && console.log(this.viewId, "viewWillAppear");
        var promise = super.viewWillAppear(...arguments);

        if (this.control.supportsStates()) {
            this._requestStates();
        }

        return promise;
    }

    viewDidAppear() {
        Debug.BaseCard && console.log(this.viewId, "viewDidAppear");
        var promise = super.viewDidAppear(...arguments);

        if (this.control.supportsStates()) {
            this._forceStateUpdate(); // otherwise "waiting for states" wouldn't be removed (when app was in BG very long)


            this._registerForStates(null, this.getStateIDs());
        }

        return promise;
    }

    viewWillDisappear(viewRemainsVisible) {
        Debug.BaseCard && console.log(this.viewId, "viewDidLoad");

        if (this.control.supportsStates()) {
            this._unregisterStates();
        } // ensure to reject any pending waitingForStatesDefers --> when the app is sent to the BG e.g. the waiting
        // for states popup is dismissed.


        this._waitingForStatesDefer && this._waitingForStatesDefer.reject();
        this._waitingForStatesDefer = null;
        return super.viewWillDisappear(...arguments);
    }

    /**
     * return all state id's which are needed for the cell
     * @return {string[]}
     */
    getStateIDs() {
        return ["stateTextShort", "stateTextColor", "stateIcon", "stateColor", "stateIconSmall", "stateTintColor", "presenceSimulation", "messageCenterEntries", "universalIsLocked", "universalLockReason"];
    }

    // Function parameter can't be named like the function -> iOS 9.x
    allStatesReceived(allStatesArrived) {
        Debug.BaseCard && console.log(this.viewId, "allStatesReceived: " + allStatesArrived);
        this.didReceiveAllStates = allStatesArrived;
        this.element.toggleClass("waiting-for-states", !allStatesArrived);

        if (allStatesArrived) {// will be updated and removed below..!
        } else if (!this._hasCommandsInQueue) {
            this._showWaiting(_('requesting-status'));
        }
    }

    receivedStates(states) {
        // ATTENTION: when using more states, add them to getStateIDs to register for the updates
        Debug.BaseCard && console.log(this.viewId, "receivedStates");
        var stateIcon = states.stateIcon,
            // getter.. only call once, store result.
            tintColor = states.stateTintColor,
            title2 = this.getTitle2(),
            textColor,
            iconColor,
            subtitle,
            smallIcon;

        if (Debug.Sorting) {
            title2 = "(" + this.control.defaultRating + ") " + this.control.getName();
        }

        if (!stateIcon) {
            stateIcon = this.control.getIcon();
        }

        if (states.universalIsLocked) {
            textColor = window.Styles.colors.red;
            iconColor = states.stateColor;
            subtitle = _("control.lock.locked-title");
            smallIcon = {
                iconSrc: Icon.LOCK,
                color: window.Styles.colors.red
            };
        } else if (!this.control.isConfigured()) {
            smallIcon = null;
            subtitle = _("unconfigured.title");
            textColor = window.Styles.colors.orange;
        } else {
            textColor = states.stateTextColor;
            iconColor = states.stateColor;

            if (states.presenceSimulation && states.presenceSimulation.active) {
                smallIcon = {
                    iconSrc: Icon.PRESENCE_SIMULATION
                };
                subtitle = _('presence-simulation');
                textColor = null;
                tintColor = window.Styles.colors.blue;
            } else {
                subtitle = this.getSubtitle();
                smallIcon = states.stateIconSmall;
            }
        } // Ensures that unnecessary modifications are avoided.


        this.setSubtitle(subtitle);
        this.setTitle2(title2);
        this.setTintColor(tintColor);
        this.setTextColor(textColor);
        this.setSmallIcon(smallIcon);
        this.setIcon(stateIcon);
        this.setIconColor(iconColor);
        this.states = states;

        this._updateControls();

        this._removeWaiting();
    }

    getTitle(content) {
        var title;

        if (content.showGroupDetails) {
            title = this.control.groupDetail || NBR_SPACE;
        } else {
            var group, groupName;

            if (content.displayRoomName) {
                group = this.control.getRoom();
                groupName = group && group.name || _('room.without');
            } else {
                group = this.control.getCategory();
                groupName = group && group.name || _('category.without');
            }

            title = groupName;
        }

        return title;
    }

    getTitle2() {
        return this.control.getName();
    }

    getSubtitle() {
        if (this.control.isConfigured()) {
            return this.control.getStateTextShort();
        } else {
            return _("unconfigured.title");
        }
    }

    /**
     * Navigating to the controlContent will delayed if waiting for states.
     * A promise is stored to avoid opening the content multiple times.
     * A popup informs the user that states are still missing.
     * This popup may be cancelled.
     */
    onSelected() {
        this._cancelPress(true);

        if (this._waitingForOpenPromise) {
            return; // already pending.
        } // when tapped while states aren't ready show a popup, otherwise it's unclear why the tap won't work


        this._waitingForOpenPromise = NavigationComp.showWaitingFor(this.waitingForStatesPromise, _('requesting-status'), null, true).then(() => {
            // actually open the controlContent.
            NavigationComp.showControlContent(this.control, this._contentDetail);
            super.onSelected(...arguments); // also call base, eg. for search... (before navigation to give it the chance to navigate back!)
        }, err => {
            console.warn(this.viewId, "onSelected - readyForContentPromise rejected!");
            console.warn(this.viewId, err);
        }).finally(() => {
            this._waitingForOpenPromise = null;
        });
    }

    sendCommand(cmd, type, cUuidAction, dr, argTexts) {
        this._shouldPreventInteraction(true);

        var promise = this.control.sendCommand(cmd, type, cUuidAction, dr, argTexts, CmdSrc.CARD);
        promise.then(function success() {
            this._setCommandsInQueue && this._setCommandsInQueue(false);
        }.bind(this), function error(e) {
            console.error(e);
            this._setCommandsInQueue && this._setCommandsInQueue(false);
        }.bind(this), function notify(i) {
            console.info(i);

            if (i === Commands.SendState.QUEUED || i === Commands.SendState.PENDING) {
                this._setCommandsInQueue && this._setCommandsInQueue(true);
            }
        }.bind(this));
        return promise;
    }

    // Private Methods.
    _setPreventSortingEventListeners() {
        if (!this._alreadyAddedPreventSortingListeners) {
            this.elements.controlsPlaceholder[0].addEventListener("touchstart", this._preventSorting.bind(this), false);
            this.elements.controlsPlaceholder[0].addEventListener("touchend", this._preventSorting.bind(this), false);
            this.elements.controlsPlaceholder[0].addEventListener("touchmove", this._preventSorting.bind(this), false);
            this._alreadyAddedPreventSortingListeners = true;
        }
    }

    _preventSorting() {
        this._shouldPreventInteraction(true);
    }

    _isWaitingForControlUpdate() {
        return this._updateControlsPrms && this._updateControlsPrms.inspect().state === "pending";
    }

    /**
     * Updates the Control buttons/switches
     * @return {Q.Promise<any>}
     * @private
     */
    _updateControls() {
        Debug.BaseCard && console.log(this.viewId, "_updateControls");
        var prms;

        if (this._isWaitingForControlUpdate()) {
            if (this._updateControlsEnqueued) {
                Debug.BaseCard && console.warn("_updateControls is already updating, Queue this update!");
                prms = this._updateControlsEnqueuedPrms;
            } else {
                this._updateControlsEnqueued = true;
                prms = this._updateControlsPrms.then(function () {
                    this._updateControlsEnqueued = false;
                    Debug.BaseCard && console.warn("_updateControls queue finished, update now!");
                    return this._updateControls();
                }.bind(this));
                this._updateControlsEnqueuedPrms = prms;
            }
        } else {
            if (this.states && this.states.universalIsLocked) {
                prms = this._updateControlsForLockState(this.states.universalLockReason);
            } else if (this._shouldShowMessageCenterButton()) {
                // Add the messageCenter "short link" to the card
                // The "short link" is a button with the severity icon and color in the position of button0
                // The messageCenterEntry is prioritized don't show any other action elements if there is an messageCenter entry
                prms = this._updateControlsForMessageCenter(this.states.messageCenterEntries);
                this._lockButtonAlreadyShown = false;
            } else if (!this.control.isConfigured()) {
                prms = this._removeAllActionElements();
                this._messageCenterButtonAlreadyShown = false;
                this._lockButtonAlreadyShown = false;
            } else if (this.content.hideActionElements) {
                prms = this._removeAllActionElements();
                this._messageCenterButtonAlreadyShown = false;
                this._lockButtonAlreadyShown = false;
            } else {
                this._lockButtonAlreadyShown = false;
                prms = Q.all([this._updateSwitch(), this._updateButtons()]);
            }

            this._updateControlsPrms = prms;
        }

        return prms;
    }

    _updateSwitch() {
        var sw = this.control.getSwitch(this.states),
            prms;

        if (!this._switchButton && !this.switch && sw) {
            prms = this._addSwitch().then(function (mySwitch) {
                this.switch = mySwitch;
                this.switch.onStateChanged = this._handleSwitchChanged.bind(this, this.switch, sw.command0, sw.command1);
            }.bind(this));
        } else if (!sw && this.switch) {
            prms = this._removeSwitch();
        } else {
            prms = Q(true);
        } // wait for the promise to resolve, otherwise the switch may not be ready to be changed to active yet!


        return prms.then(function () {
            // only set the switch state if we have a switch ;)
            this.switch && this.switch.setActive(sw.active);
        }.bind(this));
    }

    _updateButtons() {
        Debug.BaseCard && console.log(this.viewId, "_updateButtons", getStackObj());

        var prms = [],
            btn0 = this.control.getButton0(this.states),
            _btn0 = btn0 && JSON.stringify(btn0),
            btn1 = this.control.getButton1(this.states),
            _btn1 = btn1 && JSON.stringify(btn1);

        if (btn0 && (!this._button0 || this._button0 !== _btn0)) {
            this._button0 = _btn0;

            if (this.button0) {
                Debug.BaseCard && console.log(this.viewId, "   removing button0 element");
                this.button0.getElement().remove();
            }

            Debug.BaseCard && console.log(this.viewId, "   adding button1");
            prms.push(this._addButton(btn0).then(function (btn) {
                this.button0 = btn;

                this._registerButton(this.button0, btn0);
            }.bind(this)));
        } else if (!btn0 && this.button0) {
            Debug.BaseCard && console.log(this.viewId, "   removing button0");
            prms.push(this.removeSubview(this.button0).then(function () {
                this.button0 = null;
                this._button0 = null;
            }.bind(this)));
        }

        if (btn1 && (!this._button1 || this._button1 !== _btn1)) {
            this._button1 = _btn1;

            if (this.button1) {
                Debug.BaseCard && console.log(this.viewId, "   removing button1 element");
                this.button1.getElement().remove();
            }

            Debug.BaseCard && console.log(this.viewId, "   adding button1");
            prms.push(this._addButton(btn1).then(function (btn) {
                this.button1 = btn;

                this._registerButton(this.button1, btn1);
            }.bind(this)));
        } else if (!btn1 && this.button1) {
            Debug.BaseCard && console.log(this.viewId, "   removing button1");
            prms.push(this.removeSubview(this.button1).then(function () {
                this.button1 = null;
                this._button1 = null;
            }.bind(this)));
        }

        return Q.all(prms);
    }

    /**
     * Decides whether or not the messagecenter button should be shown. I.e. don't show it for unimportant messages,
     * like a "stream error" or "someone else is playing spotify" on an audiozone.
     * @returns {*}
     * @private
     */
    _shouldShowMessageCenterButton() {
        var entry = null,
            shouldShow = false;

        if (this.states && this.states.messageCenterEntries && this.states.messageCenterEntries.length) {
            entry = this.states.messageCenterEntries[0];

            if (entry.setHistoricAt || entry.confirmedAt) {
                shouldShow = false;
            } else {
                switch (entry.severity) {
                    case MessageCenterHelper.SEVERITY_LEVEL.WARNING:
                    case MessageCenterHelper.SEVERITY_LEVEL.IMPORTANT:
                    case MessageCenterHelper.SEVERITY_LEVEL.CRITICAL:
                        shouldShow = true;
                        break;

                    default:
                        shouldShow = false;
                        break;
                }
            }
        }

        return shouldShow;
    }

    /**
     * Adds the messageCenter button to the card and removes all other action elements
     * @param messageCenterEntries messageCenterEntries (sorted after severity)
     * @returns Q.Promise<any>
     * @private
     */
    _updateControlsForMessageCenter(messageCenterEntries) {
        Debug.BaseCard && console.log(this.viewId, "_updateControlsForMessageCenter", getStackObj());
        var msgCentButton = {
            iconSrc: MessageCenterHelper.getIconForSeverityEntry(messageCenterEntries[0], true, true),
            command: function () {
                this._cancelPress();

                NavigationComp.showState(ScreenState.MessageCenterMessageScreen, {
                    entry: messageCenterEntries[0]
                }, AnimationType.FADE);
            }.bind(this),
            _btnColor: MessageCenterHelper.getColorForSeverityEntry(messageCenterEntries[0])

};        var newMsgCenterButton = JSON.stringify(msgCentButton);

        if (this._button0 !== newMsgCenterButton) {
            this._button0 = newMsgCenterButton;
            return this._removeAllActionElements(this._messageCenterButtonAlreadyShown).then(function () {
                if (this._messageCenterButtonAlreadyShown) {
                    return Q.resolve();
                } else {
                    if (this.button0) {
                        this.button0.getElement().remove();
                    }

                    return this._addCustomActionButton(msgCentButton).then(function (btn) {
                        this.button0 = btn;

                        this._registerButton(this.button0, msgCentButton);

                        return GUI.animationHandler.schedule(function () {
                            this.button0.getElement().first().css("fill", msgCentButton._btnColor);
                            this._messageCenterButtonAlreadyShown = true;
                        }.bind(this));
                    }.bind(this));
                }
            }.bind(this));
        } else {
            return Q.resolve();
        }
    }

    /**
     * Removes all action elements
     * @param [reason]    optional, may provide insight on why the lock is active
     * @returns Q.Promise<any>
     * @private
     */
    _updateControlsForLockState(reason) {
        return this._removeAllActionElements(this._lockButtonAlreadyShown).then(function () {
            this._lockButtonAlreadyShown = true;
        }.bind(this));
    }

    /**
     * Removes all action elements (buttons and switches) if available
     * @private
     */
    _removeAllActionElements(donNotRemoveFirstButton) {
        Debug.BaseCard && console.log(this.viewId, "_removeAllActionElements", getStackObj());
        var prms = []; // remove all other actionElements

        if (this.button0 && !donNotRemoveFirstButton) {
            Debug.BaseCard && console.log(this.viewId, "   removing button0");
            prms.push(this.removeSubview(this.button0).then(function () {
                this.button0 = null;
                this._button0 = null;
            }.bind(this)));
        }

        if (this.button1) {
            Debug.BaseCard && console.log(this.viewId, "   removing button1");
            prms.push(this.removeSubview(this.button1).then(function () {
                this.button1 = null;
                this._button1 = null;
            }.bind(this)));
        }

        if (this.switch) {
            prms.push(this._removeSwitch());
        }

        return Q.all(prms);
    }

    _addSwitch() {
        var mySwitchEl = $('<div class="controls__switch">'),
            mySwitch = new GUI.LxSwitch(this, mySwitchEl[0], window.Styles.colors.stateActive); // Dispose all event listeners, so we can just use the overlaying button

        mySwitch.eventHandler.dispose();
        return GUI.animationHandler.prepend(mySwitchEl, this.elements.controlsPlaceholder).then(function () {
            // as the switch itself is too small, lay over a larger button.
            this._switchButton = new GUI.LxButton(this, mySwitchEl[0]);
            return this.addToHandledSubviews(this._switchButton).then(function () {
                this._switchButton.onButtonTapped = function (source, event) {
                    this._shouldPreventInteraction(true);

                    this.switch._handleTap(event);
                }.bind(this);

                this._setPreventSortingEventListeners();

                return mySwitch;
            }.bind(this));
        }.bind(this));
    }

    /**
     * Removes the switch Button
     * @return {Q.Promise<any>}
     * @private
     */
    _removeSwitch() {
        var prms; // ensure to remove the switchbutton too.

        if (this._switchButton) {
            prms = this.removeSubview(this._switchButton).then(function () {
                this._switchButton = null;
            }.bind(this));
        } else {
            prms = Q.resolve();
        }

        if (this.switch) {
            prms.then(function () {
                GUI.animationHandler.remove(this.switch.getElement()).then(function () {
                    this.switch = null;
                }.bind(this));
            }.bind(this));
        }

        return prms;
    }

    /**
     *
     * @param btn
     * @return {Q.Promise<GUI.LxButton>}
     * @private
     */
    _addButton(btn) {
        Debug.BaseCard && console.log(this.viewId, "_addButton");
        var prms,
            myBtnEl = $(ImageBox.getResourceImageWithClasses(btn.iconSrc, "controls__btn")),
            myBtn = new GUI.LxButton(this, myBtnEl[0], black54, null, true);
        myBtn.useChildsAsActiveParts('fill'); // Don't do that, it will cancel the button event with the smallest movement
        //myBtn.setCancelOnDrag(true);

        prms = this.appendSubview(myBtn, this.elements.controlsPlaceholder);

        this._setPreventSortingEventListeners();

        return prms.then(function () {
            return myBtn;
        });
    }

    /**
     * Initializes and adds the button to the DOM (e.g. message-center or lock info)
     * @param btn
     * @returns Q.Promise(<GUI.LxButton>)
     * @private
     */
    _addCustomActionButton(btn) {
        var prms,
            myBtnEl = $(ImageBox.getResourceImageWithClasses(btn.iconSrc, "controls__btn message-center__btn")),
            myBtn = new GUI.LxButton(this, myBtnEl[0], Color.WHITE, null, true); // Don't do that, it will cancel the button event with the smallest movement
        //myBtn.setCancelOnDrag(true);

        prms = this.appendSubview(myBtn, this.elements.controlsPlaceholder);

        this._setPreventSortingEventListeners();

        return prms.then(function () {
            return myBtn;
        });
    }

    /**
     * will register the callbacks on the button object based on the configuration object provided
     * @param btn   the button on which to register the callbacks
     * @param cfg   the config that specifies what commands are to be sent when such a button is hit.
     * @private
     */
    _registerButton(btn, cfg) {
        var cmdObj;

        if (typeof cfg.command === "object") {
            cmdObj = cfg.command;
            cmdObj.hit && this._registerBtnCallback(btn, "onButtonHit", cmdObj.hit);
            cmdObj.release && this._registerBtnCallback(btn, "onButtonReleased", cmdObj.release);
            cmdObj.tap && this._registerBtnCallback(btn, "onButtonTapped", cmdObj.tap);
            cmdObj.doubleTap && this._registerBtnCallback(btn, "onButtonDoubleTapped", cmdObj.doubleTap);

            if (cmdObj.tick) {
                btn.activateTicks(cmdObj.tickInterval);

                this._registerBtnCallback(btn, "onButtonTicked", cmdObj.tick);
            }
        } else if (typeof cfg.command === "function" || typeof cfg.command === "string") {
            btn.onButtonTapped = this._sendCmd.bind(this, cfg.command);
        }

        btn.setEnabled(!cfg.disabled);
    }

    /**
     * Helper method to register
     * @param btn
     * @param callback
     * @param cmd
     * @private
     */
    _registerBtnCallback(btn, callback, cmd) {
        if (typeof cmd === "string" || typeof cmd === "function") {
            btn[callback] = this._sendCmd.bind(this, cmd);
        } else if (typeof cmd === "object") {
            btn[callback] = this._sendCmds.bind(this, cmd);
        } else {
            console.error("Cannot register button callback " + callback + " - only command-strings or functions allowed!");
        }
    }

    /**
     * Will send all cmds in the array provided.
     * @param cmds
     * @private
     */
    _sendCmds(cmds) {
        Object.values(cmds).forEach(this._sendCmd.bind(this));
    }

    /**
     * Will send the command provided as argument. If the argument is a function, it will call it and sent it's
     * resulting string.
     * @param cmd
     * @private
     */
    _sendCmd(cmd) {
        this._shouldPreventInteraction(true);

        if (typeof cmd === "function") {
            cmd = cmd();
        }

        if (cmd !== undefined) {
            // also possible, that another action is triggered.. (eg. App/Webpage)
            return this.sendCommand(cmd);
        }
    }

    _handleSwitchChanged(sw, command0, command1, active) {
        this._sendCmd(active ? command1 : command0).done(null, function () {
            sw.setActive(!active);
        });
    }

    /**
     * Takes an hex, rgb or rgba color, converts it into an rgba color with an alpha chanel of 0.4
     * @param color
     * @private
     */
    setTintColor(color) {
        if (this.__prevTintColor === color) {
            return;
        }

        this.__prevTintColor = color;
        GUI.animationHandler.setCssAttr(this.elements.iconPlaceholder, "fill", color || "");
        return super.setTintColor(...arguments);
    }

    setIconColor(color) {
        if (this.__prevIconColor === color) {
            return;
        }

        this.__prevIconColor = color;
        return super.setIconColor(...arguments);
    }

    setIcon(iconSrc) {
        if (this.__prevStateIcon !== iconSrc) {
            this.__prevStateIcon = iconSrc;
        }

        return super.setIcon(...arguments);
    }

    setSmallIcon(iconObj) {
        var str = JSON.stringify(iconObj);

        if (this.__prevSmallIcon === str) {
            return;
        }

        this.__prevSmallIcon = str;
        return super.setSmallIcon(...arguments);
    }

    setSubtitle(text) {
        if (this.__prevSubtitle === text) {
            return;
        }

        this.__prevSubtitle = text;
        return super.setSubtitle(...arguments);
    }

    setTextColor(arg) {
        if (this.__prevTextColor === arg) {
            return;
        }

        this.__prevTextColor = arg;
        return super.setTextColor(...arguments);
    }

    setTitle2(title2) {
        if (this.__prevTitle2 !== title2) {
            this.__prevTitle2 = title2;
        }

        return super.setTitle2(...arguments);
    }

    _showWaiting(text) {
        Debug.BaseCard && console.log(this.viewId, "_showWaiting: '" + text + "', hasWaitingIndicator: " + !!this.elements.waitingIndicator);
        this.setSubtitle(text);
        this.setSmallIcon({
            iconSrc: "<svg>"
        });
        this.setTextColor();
        this.setIconColor();
        this.setTintColor();

        if (!this.elements.waitingIndicator) {
            this.elements.waitingIndicator = $('<div class="icon-placeholder__spinner"><div class="css-spinner css-spinner-b' + (this.displayAsCell() ? ' css-spinner--small' : '') + '" /></div>');
            this.elements.iconCntr.append(this.elements.waitingIndicator);
        }
    }

    _removeWaiting() {
        Debug.BaseCard && console.log(this.viewId, "_removeWaiting, hasWaitingIndicator: " + !!this.elements.waitingIndicator);

        if (this.elements.waitingIndicator) {
            this.elements.waitingIndicator.remove();
            delete this.elements.waitingIndicator;
        }
    }

    _setCommandsInQueue(inQueue) {
        this._hasCommandsInQueue = inQueue;
        this.element.toggleClass("commands-in-queue", inQueue);

        if (inQueue) {
            this._showWaiting(_('command.sending'));
        } else if (!this.didReceiveAllStates) {
            this._showWaiting(_('requesting-status'));
        }
    }

}

GUI.TableViewV2.Cells.ControlBaseCard = ControlBaseCard;
